import React, { useMemo } from 'react';
import Container from '../../atoms/Container/Container';
import Tabs from '../../molecules/Tabs/Tabs';

const subMenuData = [
  {
    itemKey: 'purchase-gift-certificate',
    label: 'Purchase Gift Certificate',
    uri: '/gift-certificates/'
  },
  {
    itemKey: 'redeem-gift-certificate',
    label: 'Redeem Gift Certificate',
    uri: '/gift-certificates/redeem/'
  },
  {
    itemKey: 'check-gift-certificate-balance',
    label: 'Check Gift Certificate Balance',
    uri: '/gift-certificates/check-balance/'
  }
];

const GiftCertificatesTab = ({
  children,
  pageId
}) => {
  const subMenu = useMemo(() => {
    const items = subMenuData?.map(item => ({
      uri: item.uri,
      label: item.label,
      itemKey: item.itemKey,
      active: item.itemKey === pageId
    }));
    return items;
  }, [pageId]);

  return (
    <Container>
      <Tabs items={subMenu} noWrap={false} align="left" />
      {children}
    </Container>
  );
};

export default GiftCertificatesTab;
